
























import { Vue, Component } from "vue-property-decorator";
import Rview from "@/components/routerView/index.vue";
import { UserModule } from "@/store/user";
import { OtherModule } from "@/store/other";
@Component({
  components: { Rview },
})
export default class extends Vue {
  created(): void {
    if (UserModule.token !== null && UserModule.token !== "") {
      this._loading = true;
      this.$message.success("로그인 되었습니다. 홈페이지로 이동하겠습니다.");
      setTimeout(() => {
        this.$router.replace("/");
      }, 1000);
    }
  }

  get is_mobile(): boolean {
    return OtherModule.is_mobile;
  }
}
